@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.container {
  min-height: 100%;
  padding: 0 0 0 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  .image-container {
    display: inline-block;
    margin: 10px;
    position: relative;
}

.download-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}
  /* Footer styles */


 